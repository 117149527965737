<template>
  <el-carousel trigger="click" height="200px" :autoplay="false" ref="nop">
    <div @touchstart="start($event)" @touchmove="move($event)">
      <el-carousel-item v-for="(item, i) in data" :key="i">
        <img :src="item.img" alt="" class="img" />
        <h3 class="small">{{ item.title }}</h3>
        <p>{{ item.text }}</p>
        <a class="route" @click="go(i)"></a>
      </el-carousel-item>
    </div>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          img: require("@/assets/img/imgWeb1.png"),
          imgBg: require("@/assets/img/bai.png"),
          title: "茶行业",
          text: "茶企数字化整套解决方案",
        },
        {
          img: require("@/assets/img/imgWeb2.png"),
          imgBg: require("@/assets/img/bai.png"),
          title: "消费品行业",
          text: "品牌赋能、渠道建设",
        },
        {
          img: require("@/assets/img/imgWeb3.png"),
          imgBg: require("@/assets/img/bai.png"),
          title: "政府与协会",
          text: "链接政企、服务社会",
        },
        {
          img: require("@/assets/img/imgWeb4.png"),
          imgBg: require("@/assets/img/bai.png"),
          title: "工业领域",
          text: "智能化赋能数字工厂建设",
        },
        {
          img: require("@/assets/img/imgWeb5.png"),
          imgBg: require("@/assets/img/bai.png"),
          title: "医疗卫生",
          text: "新冠接种、儿童预防接种",
        },
      ],
    };
  },
  methods: {
    go(index) {
      console.log(index);
      switch (index) {
        case 0:
          this.$router.push({ path: "/tea" });
          break;
        case 1:
          this.$router.push({ path: "/health" });
          break;
        case 2:
          this.$router.push({ path: "/society" });
          break;
        case 3:
          this.$router.push({ path: "/industry" });
          break;
        case 4:
          this.$router.push({ path: "/hygiene" });
          break;
        default:
          break;
      }
    },
    // 滑动切换
    start(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    move(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (this.startTime == undefined || nowtime > this.startTime) {
        if (this.startX - this.moveX <= 0) {
          // 右滑触发
          this.prev();
          return false;
        } else {
          this.next();
          return false;
        }
      }
    },
    prev() {
      this.$refs.nop.prev();
      this.startTime = new Date().getTime() + 500;
    },
    next() {
      this.$refs.nop.next();
      this.startTime = new Date().getTime() + 500;
    },
    // ------------------------滑动切换
  },
};
</script>
<style scoped lang="scss">
.el-carousel-item {
  text-align: center;
}
.img {
  width: 51px;
  height: 55px;
  margin: auto;
}
.small {
  height: 19px;
  font-size: 17px;
  font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
  font-weight: bold;
  color: #0b3c7f;
  line-height: 19px;
  letter-spacing: 9px;
  margin: 13px 0 6px;
}
p {
  height: 13px;
  font-size: 10px;
  font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
  font-weight: 400;
  color: #000000;
  line-height: 13px;
}
.route {
  display: block;
  width: 160px;
  height: 65px;
  background: url(../../../assets/img/Component4.png);
  background-size: 100% 100%;
  margin: auto;
}
// ::v-deep .el-carousel__indicators {
//   // 指示器
//   left: unset;
//   transform: unset;
//   right: 2%;
// }
::v-deep .el-carousel__button {
  // 指示器按钮
  width: 1rem;
  height: 0.1rem;
  border: none;
  border-radius: 0%;
  background-color: RGBA(203, 203, 203, 1);
}
::v-deep .is-active .el-carousel__button {
  // 指示器激活按钮
  width: 1rem;
  height: 0.1rem;
  border-radius: 0%;
  background: #4eb3ff;
}
</style>
